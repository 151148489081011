import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Toast } from 'flowbite-react';
import type { ComponentType, ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

type Color = 'info' | 'error';

interface ToastAlertProps
  extends Omit<ReturnType<typeof useToastState>, 'showToast'>,
    Partial<ToastConfig> {
  children?: ReactNode;
}

function getIconColor(color: Color) {
  switch (color) {
    case 'error':
      return 'bg-red-100 text-red-500';
    case 'info':
    default:
      return 'bg-primary-100 text-blue-500';
  }
}

function ToastAlert({
  dismissToast,
  message = '',
  Icon = InformationCircleIcon,
  color = 'info',
  isToastVisible,
  children,
}: ToastAlertProps) {
  if (!isToastVisible) {
    return null;
  }

  return (
    <Toast className="fixed bottom-10 right-10 z-80">
      <div
        className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg ${getIconColor(
          color
        )}`}
      >
        <Icon className="h-5 w-5" />
      </div>
      <div className="ml-3 text-base text-gray-500">{children ?? message}</div>
      <Toast.Toggle onClick={dismissToast} />
    </Toast>
  );
}

export interface ToastConfig {
  message: string;
  color: Color;
  Icon: ComponentType<{ className?: string }>;
}

/**
 * Prefer to use ToastContext when possible
 */
export function useToastState() {
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [toastConfig, setToastConfig] = useState<Partial<ToastConfig>>({});
  const [toastTimeout, setToastTimeout] = useState<
    NodeJS.Timeout | undefined
  >();
  const [toastDuration, setToastDuration] = useState(8000);

  useEffect(() => {
    if (isToastVisible) {
      const timeout = setTimeout(() => dismissToast(), toastDuration);
      setToastTimeout(timeout);
    }
    // restart timeout with new config
  }, [toastConfig]);

  const dismissToast = () => {
    clearTimeout(toastTimeout);
    setToastConfig({});
    setToastTimeout(undefined);
    setIsToastVisible(false);
  };

  const showToast = ({
    duration,
    ...config
  }: Partial<ToastConfig> & { duration?: number } = {}) => {
    setToastConfig(config);
    setToastDuration(duration ?? 8000);
    setIsToastVisible(true);
  };

  return { dismissToast, showToast, isToastVisible, ...toastConfig };
}

export default ToastAlert;
