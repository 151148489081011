import type { PropsWithChildren } from 'react';
import React, { createContext, useContext } from 'react';

import ToastAlert, { useToastState } from '@/components/ToastAlert';

// Create the context with default values
const ToastContext = createContext<ReturnType<typeof useToastState>>({
  isToastVisible: false,
  dismissToast: () => {},
  showToast: () => {},
});

/**
 * Use this hook to show a toast message
 */
export function useToastContext() {
  const { showToast, dismissToast } = useContext(ToastContext);
  return { showToast, dismissToast };
}

/**
 * Allows to create toasts on layout level that will persist between page switches
 */
export const ToastContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const toastContextValue = useToastState();
  return (
    <>
      <ToastContext.Provider value={toastContextValue}>
        {children}
      </ToastContext.Provider>
      <ToastAlert {...toastContextValue} />
    </>
  );
};
