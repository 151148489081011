import '../styles/global.css';

import { createPagesBrowserClient } from '@supabase/auth-helpers-nextjs';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { Flowbite } from 'flowbite-react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import type { ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import { GlobalContextProvider } from '@/contexts/GlobalContext';
import { ToastContextProvider } from '@/contexts/ToastContext';
import useAmplitudeInit, { amplitude } from '@/hooks/useAmplitudeInit';
import { customFlowbiteTheme } from '@/utils/customFlowbiteTheme';
// If loading a variable font, you don't need to specify the font weight
const inter = Inter({ subsets: ['latin'] });

// https://nextjs.org/docs/basic-features/layouts
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  useAmplitudeInit();
  // Uses values in .env.local
  const [supabase] = useState(() => createPagesBrowserClient());

  supabase.auth.onAuthStateChange((event, session) => {
    if (event === 'SIGNED_IN' && amplitude) {
      amplitude.setUserId(session?.user?.id);
    }
  });

  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <SessionContextProvider
      supabaseClient={supabase}
      initialSession={pageProps.initialSession}
    >
      <Flowbite theme={{ theme: customFlowbiteTheme }}>
        <GlobalContextProvider>
          <ToastContextProvider>
            {getLayout(
              <Component {...pageProps} className={inter.className} />
            )}
          </ToastContextProvider>
        </GlobalContextProvider>
      </Flowbite>
    </SessionContextProvider>
  );
};

export default MyApp;
