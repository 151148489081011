import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';

import type { Database } from '@/types/supabase';
import type {
  OrganizationData,
  PersonData,
  UserData,
} from '@/types/supabaseHelperTypes';

// Define the types of the variables you want to store in the context
interface GlobalContextData {
  org: OrganizationData | null;
  user: UserData | null;
  userPerson: PersonData | null;
  refreshData: () => void;
}

const createInitialState = () =>
  ({
    org: null,
    user: null,
    userPerson: null,
    refreshData: () => {},
  } satisfies GlobalContextData);

// Create the context with default values
const GlobalContext = createContext<GlobalContextData>(createInitialState());

// Create a custom hook for easier access to the context values
export function useGlobalContext() {
  return useContext(GlobalContext);
}

interface GlobalContextProviderProps {
  children: React.ReactNode;
}

// Fetch the values of the variables and provide them to the whole app
export const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({
  children,
}) => {
  const [contextData, setContextData] = useState<GlobalContextData>(
    createInitialState()
  );
  const session = useSession();
  const supabase = useSupabaseClient<Database>();
  const router = useRouter();

  const [refreshDataState, setRefreshDataState] = useState<Number>(
    new Date().getTime()
  );

  const refreshData = () => {
    setRefreshDataState(new Date().getTime());
  };

  useEffect(() => {
    if (!session?.user.id) return;
    supabase
      .from('users')
      .select('*')
      .eq('id', session?.user.id)
      .single()
      .then(async ({ data: user }) => {
        // If user is deactivated, log them out
        if (!user?.is_active) {
          router.push('/logout');
          return;
        }

        setContextData((prev) => ({ ...prev, user, refreshData }));
        // Get the organization data based on the user's org_id
        if (user) {
          const { data: organization, error } = await supabase
            .from('organizations')
            .select('*')
            .eq('id', user.org_id)
            .single();

          const { data } = await supabase
            .from('persons')
            .select('*')
            .eq('user_id', user.id)
            .eq('org_id', user.org_id)
            .limit(1);
          const userPerson = data ? data[0] : null;

          if (error) {
            // eslint-disable-next-line no-console
            console.error('Error fetching organization:', error);
          } else {
            // Store the organization data in the context
            setContextData((prev) => ({
              ...prev,
              org: organization,
              userPerson: userPerson || null,
              refreshData,
            }));
          }
        }
      });
  }, [session?.user.id, refreshDataState]);

  return (
    <GlobalContext.Provider value={contextData}>
      {children}
    </GlobalContext.Provider>
  );
};
