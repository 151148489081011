import { useEffect } from 'react';

// eslint-disable-next-line import/no-mutable-exports
export let amplitude;

const AMPLITUDE_API_KEY =
  process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ||
  '82baf979ed9f5bd5dc5719c041303bc1'; // Public amplitude key

const useAmplitudeInit = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      const initAmplitude = async () => {
        amplitude = await import('@amplitude/analytics-browser');
        amplitude.init(AMPLITUDE_API_KEY, undefined, {
          logLevel: amplitude.Types.LogLevel.Warn,
          defaultTracking: {
            sessions: true,
          },
        });
      };
      initAmplitude();
    }
  }, []);
};

export default useAmplitudeInit;
